import { IconDefinition, Library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { ShareLinkFrameLocation } from '@icepanel/platform-api-client'
import VueRouter from 'vue-router'
import Vuetify from 'vuetify/lib'
import { Store } from 'vuex'
import { getModule } from 'vuex-module-decorators'

import * as env from '@/helpers/env'

import customIcons from './helpers/custom-icons'
import icons from './helpers/icons'
import routes from './routes'
import { ShareModule } from './store'

export const install = ({ vuetify, store, library, router }: { vuetify: Vuetify, store: Store<any>, library: Library, router: VueRouter }) => {
  const iconDefinitions = [...customIcons, ...icons]
  iconDefinitions.forEach(definition => {
    library.add(definition as IconDefinition)

    vuetify.framework.icons.values[`${definition.prefix}-${definition.iconName}`] = {
      component: FontAwesomeIcon,
      props: {
        icon: [definition.prefix, definition.iconName]
      }
    }
  })

  store.registerModule(ShareModule.namespace, ShareModule)

  if (env.IS_SHARE_LINK) {
    router.beforeEach((to, _, next) => {
      const shareModule = getModule(ShareModule, store)
      shareModule.setShareLinkInFrame(window !== window.parent)

      if (typeof to.query.frame === 'string') {
        shareModule.setShareLinkFrameLocation(to.query.frame as ShareLinkFrameLocation)
      }

      next()
    })

    router.addRoutes(routes)
  }
}
