import VueRouter, { Route } from 'vue-router'
import { Store } from 'vuex'
import { getModule } from 'vuex-module-decorators'

import { CommentModule } from '../comment/store'
import { DiagramModule } from '../diagram/store'
import { DomainModule } from '../domain/store'
import { EditorModule } from '../editor/store'
import { FlowModule } from '../flow/store'
import { LandscapeModule } from '../landscape/store'
import { ModelModule } from '../model/store'
import { TagModule } from '../tag/store'
import { VersionModule } from '../version/store'
import { SocketModule } from './store'

const SOCKET_CHECK_INTERVAL = 2 * 1000

export const install = ({ store, router }: { store: Store<any>, router: VueRouter }) => {
  store.registerModule(SocketModule.namespace, SocketModule)

  const checkSocket = (route?: Route) => {
    getModule(CommentModule, store)
    getModule(DiagramModule, store)
    getModule(DomainModule, store)
    getModule(EditorModule, store)
    getModule(FlowModule, store)
    getModule(LandscapeModule, store)
    getModule(ModelModule, store)
    getModule(TagModule, store)
    getModule(VersionModule, store)

    const socketModule = getModule(SocketModule, store)
    socketModule.checkSocket({
      route
    })
  }

  router.afterEach(to => {
    checkSocket(to)
  })

  window.setInterval(() => {
    checkSocket()
  }, SOCKET_CHECK_INTERVAL)

  document.addEventListener('visibilitychange', () => {
    checkSocket()
  })

  window.addEventListener('online', () => {
    checkSocket()
  })

  window.addEventListener('offline', () => {
    checkSocket()
  })
}
