import { Route } from 'vue-router'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import Status from '@/helpers/status'
import { createSocket, Socket } from '@/plugins/socket'
import { IStore } from '@/plugins/store'

import router from '../../plugins/router'

/**
 * how long the app can be inactive or offline for before the socket automatically disconnects (in ms)
 */
const SOCKET_INACTIVE_KEEP_ALIVE = 60 * 60 * 1000

/**
 * how long after the socket is disconnected should the app continue to look like it is connected and make requests for (in ms)
 * allows the app to handle being reconnected from the websocket without interrupting the user's work
 */
const SOCKET_DISCONNECT_KEEP_ALIVE = 30 * 1000

export interface ISocketModule {
  socket: Socket | null
  socketStatus: Status

  socketLastDisconnected: number
  socketLastActive: number

  socketSubscriptionActive: boolean
}

const name = 'socket'

@Module({
  name,
  namespaced: true
})
export class SocketModule extends VuexModule implements ISocketModule {
  static namespace = name

  socket: Socket | null = null
  socketStatus = new Status()

  socketLastDisconnected = 0
  socketLastActive = 0

  socketSubscriptionActive = false

  get socketError () {
    return this.socketStatus.errorMessage
  }

  get socketSubscriptionError () {
    const rootState: IStore = this.context.rootState
    return (
      rootState.landscape.landscapeSubscriptionStatus?.errorMessage ||
      rootState.version.versionsSubscriptionStatus?.errorMessage ||

      rootState.comment.commentsSubscriptionStatus?.errorMessage ||
      rootState.diagram.diagramsSubscriptionStatus.errorMessage ||
      rootState.diagram.diagramContentsSubscriptionStatus.errorMessage ||
      rootState.diagram.diagramGroupsSubscriptionStatus.errorMessage ||
      rootState.domain.domainsSubscriptionStatus.errorMessage ||
      rootState.editor.editorSubscriptionStatus.errorMessage ||
      rootState.flow.flowsSubscriptionStatus?.errorMessage ||
      rootState.model.objectsSubscriptionStatus?.errorMessage ||
      rootState.model.connectionsSubscriptionStatus?.errorMessage ||
      rootState.tag.tagsSubscriptionStatus?.errorMessage ||
      rootState.tag.tagGroupsSubscriptionStatus?.errorMessage
    )
  }

  @Mutation
  setSocket (socket: Socket | null) {
    if (this.socket && this.socket !== socket) {
      this.socket.disconnect()

      this.socket.listeners('connect').length = 0
      this.socket.listeners('connect_error').length = 0
      this.socket.listeners('disconnect').length = 0

      this.socket.listeners('comment-initial-value').length = 0
      this.socket.listeners('comment-added').length = 0
      this.socket.listeners('comment-modified').length = 0
      this.socket.listeners('comment-removed').length = 0

      this.socket.listeners('diagram-initial-value').length = 0
      this.socket.listeners('diagram-added').length = 0
      this.socket.listeners('diagram-modified').length = 0
      this.socket.listeners('diagram-removed').length = 0

      this.socket.listeners('diagram-content-initial-value').length = 0
      this.socket.listeners('diagram-content-added').length = 0
      this.socket.listeners('diagram-content-modified').length = 0
      this.socket.listeners('diagram-content-removed').length = 0

      this.socket.listeners('diagram-group-initial-value').length = 0
      this.socket.listeners('diagram-group-added').length = 0
      this.socket.listeners('diagram-group-modified').length = 0
      this.socket.listeners('diagram-group-removed').length = 0

      this.socket.listeners('domain-initial-value').length = 0
      this.socket.listeners('domain-added').length = 0
      this.socket.listeners('domain-modified').length = 0
      this.socket.listeners('domain-removed').length = 0

      this.socket.listeners('editor-locations').length = 0
      this.socket.listeners('editor-location-modified').length = 0
      this.socket.listeners('editor-typing').length = 0
      this.socket.listeners('editor-typing-modified').length = 0

      this.socket.listeners('flow-initial-value').length = 0
      this.socket.listeners('flow-added').length = 0
      this.socket.listeners('flow-modified').length = 0
      this.socket.listeners('flow-removed').length = 0

      this.socket.listeners('landscape').length = 0

      this.socket.listeners('model-connection-initial-value').length = 0
      this.socket.listeners('model-connection-added').length = 0
      this.socket.listeners('model-connection-modified').length = 0
      this.socket.listeners('model-connection-removed').length = 0

      this.socket.listeners('model-object-initial-value').length = 0
      this.socket.listeners('model-object-added').length = 0
      this.socket.listeners('model-object-modified').length = 0
      this.socket.listeners('model-object-removed').length = 0

      this.socket.listeners('tag-initial-value').length = 0
      this.socket.listeners('tag-added').length = 0
      this.socket.listeners('tag-modified').length = 0
      this.socket.listeners('tag-removed').length = 0

      this.socket.listeners('tag-group-initial-value').length = 0
      this.socket.listeners('tag-group-added').length = 0
      this.socket.listeners('tag-group-modified').length = 0
      this.socket.listeners('tag-group-removed').length = 0

      this.socket.listeners('version-initial-value').length = 0
      this.socket.listeners('version-added').length = 0
      this.socket.listeners('version-modified').length = 0
      this.socket.listeners('version-removed').length = 0
    }

    this.socket = socket
  }

  @Mutation
  setSocketStatus (...params: Parameters<typeof this.socketStatus.set>) {
    this.socketStatus.set(...params)
  }

  @Mutation
  setSocketLastDisconnected (socketLastDisconnected: number) {
    this.socketLastDisconnected = socketLastDisconnected
  }

  @Mutation
  setSocketLastActive (socketLastActive: number) {
    this.socketLastActive = socketLastActive
  }

  @Mutation
  setSocketSubscriptionActive (socketSubscriptionActive: boolean) {
    this.socketSubscriptionActive = socketSubscriptionActive
  }

  @Action({ rawError: true })
  async connectSocket () {
    const socket = createSocket({
      auth: async auth => {
        const token = await this.context.dispatch('user/getIdToken', undefined, { root: true })
        auth({ bearer: token })
      }
    })

    socket.on('connect', () => {
      const reconnect = !!this.socketLastDisconnected

      this.context.commit('setSocketStatus', Status.success())
      this.context.commit('setSocketLastDisconnected', 0)

      this.context.dispatch('checkSocket', {
        reconnect
      })
    })

    socket.on('connect_error', err => {
      this.context.commit('setSocketStatus', Status.error(err.message))
    })

    socket.on('disconnect', reason => {
      this.context.commit('setSocketStatus', Status.loading())

      if (reason !== 'io client disconnect') {
        this.context.commit('setSocketLastDisconnected', Date.now())
      }

      if (reason === 'io server disconnect') {
        // refresh org incase permissions changed
        this.context.dispatch('organization/organizationsList', undefined, { root: true })

        socket.connect()
      }
    })

    this.context.commit('setSocket', socket)
    this.context.commit('setSocketStatus', Status.loading())

    const connect = new Promise<void>((resolve) => socket.once('connect', () => resolve()))
    const connectError = new Promise<void>((resolve, reject) => socket.once('connect_error', err => reject(err)))

    socket.connect()

    await Promise.race([connect, connectError])

    return socket
  }

  @Action({ rawError: true })
  async checkSocket (opts: { route?: Route, reconnect?: boolean } = {}) {
    const rootState: IStore = this.context.rootState

    const active = document.visibilityState === 'visible' && navigator.onLine
    if (active) {
      this.context.commit('setSocketLastActive', Date.now())
    }

    const route = opts.route || router.currentRoute
    const requiresSocket = route.matched.some(o => o.meta.requiresSocket)

    const disconnectKeepAlive = !this.socketStatus.success && Date.now() < this.socketLastDisconnected + SOCKET_DISCONNECT_KEEP_ALIVE
    const inactiveKeepAlive = !active && Date.now() < this.socketLastActive + SOCKET_INACTIVE_KEEP_ALIVE

    const connectSocket = (requiresSocket && (active || inactiveKeepAlive)) || disconnectKeepAlive
    const connectSocketSubscriptions = connectSocket && requiresSocket && (this.socketStatus.success || disconnectKeepAlive)

    this.context.commit('setSocketSubscriptionActive', connectSocketSubscriptions)

    const commentsSubscriptionStatus = rootState.comment.commentsSubscriptionStatus
    const connectionsSubscriptionStatus = rootState.model.connectionsSubscriptionStatus
    const diagramsSubscriptionStatus = rootState.diagram.diagramsSubscriptionStatus
    const diagramContentsSubscriptionStatus = rootState.diagram.diagramContentsSubscriptionStatus
    const diagramGroupsSubscriptionStatus = rootState.diagram.diagramGroupsSubscriptionStatus
    const domainsSubscriptionStatus = rootState.domain.domainsSubscriptionStatus
    const editorSubscriptionStatus = rootState.editor.editorSubscriptionStatus
    const flowsSubscriptionStatus = rootState.flow.flowsSubscriptionStatus
    const landscapeSubscriptionStatus = rootState.landscape.landscapeSubscriptionStatus
    const objectsSubscriptionStatus = rootState.model.objectsSubscriptionStatus
    const tagGroupsSubscriptionStatus = rootState.tag.tagGroupsSubscriptionStatus
    const tagsSubscriptionStatus = rootState.tag.tagsSubscriptionStatus
    const versionsSubscriptionStatus = rootState.version.versionsSubscriptionStatus

    const prevLandscapeId = editorSubscriptionStatus.loadingInfo.landscapeId || editorSubscriptionStatus.successInfo.landscapeId

    const landscapeId = route.params.landscapeId
    const versionId = route.params.versionId || 'latest'

    const socketSubscriptionLandscapeChanged = (status: Status<{ landscapeId: string }, { landscapeId: string }>) => {
      return (status.loading && status.loadingInfo.landscapeId !== landscapeId) || (status.success && status.successInfo.landscapeId !== landscapeId)
    }
    const socketSubscriptionVersionChanged = (status: Status<{ versionId: string }, { versionId: string }>) => {
      return (status.loading && status.loadingInfo.versionId !== versionId) || (status.success && status.successInfo.versionId !== versionId)
    }

    if (!landscapeSubscriptionStatus.idle && (!connectSocketSubscriptions || socketSubscriptionLandscapeChanged(landscapeSubscriptionStatus))) {
      this.context.commit('landscape/landscapeUnsubscribe', undefined, { root: true })
    }
    if (!versionsSubscriptionStatus.idle && (!connectSocketSubscriptions || socketSubscriptionLandscapeChanged(versionsSubscriptionStatus))) {
      this.context.commit('version/versionsUnsubscribe', undefined, { root: true })
    }

    if (!commentsSubscriptionStatus.idle && (!connectSocketSubscriptions || socketSubscriptionLandscapeChanged(commentsSubscriptionStatus) || socketSubscriptionVersionChanged(commentsSubscriptionStatus))) {
      this.context.commit('comment/commentsUnsubscribe', undefined, { root: true })
      this.context.commit('comment/setComments', [], { root: true })
      this.context.commit('comment/setActiveComments', [], { root: true })
    }
    if (!diagramsSubscriptionStatus.idle && socketSubscriptionVersionChanged(diagramsSubscriptionStatus)) {
      this.context.commit('version/setTravelling', true, { root: true })
      this.context.commit('diagram/setDiagramsCache', rootState.diagram.diagramsCurrent, { root: true })
    }
    if (!diagramsSubscriptionStatus.idle && (!connectSocketSubscriptions || socketSubscriptionLandscapeChanged(diagramsSubscriptionStatus) || socketSubscriptionVersionChanged(diagramsSubscriptionStatus))) {
      this.context.commit('diagram/diagramsUnsubscribe', undefined, { root: true })
      this.context.commit('diagram/setDiagrams', [], { root: true })
    }
    if (!diagramContentsSubscriptionStatus.idle && socketSubscriptionVersionChanged(diagramContentsSubscriptionStatus)) {
      this.context.commit('version/setTravelling', true, { root: true })
      this.context.commit('diagram/setDiagramContentsCache', rootState.diagram.diagramContentsCurrent, { root: true })
    }
    if (!diagramContentsSubscriptionStatus.idle && (!connectSocketSubscriptions || socketSubscriptionLandscapeChanged(diagramContentsSubscriptionStatus) || socketSubscriptionVersionChanged(diagramContentsSubscriptionStatus))) {
      this.context.commit('diagram/diagramContentsUnsubscribe', undefined, { root: true })
      this.context.commit('diagram/setDiagramContents', [], { root: true })
    }
    if (!diagramGroupsSubscriptionStatus.idle && socketSubscriptionVersionChanged(diagramGroupsSubscriptionStatus)) {
      this.context.commit('version/setTravelling', true, { root: true })
      this.context.commit('diagram/setDiagramGroupsCache', rootState.diagram.diagramGroupsCurrent, { root: true })
    }
    if (!diagramGroupsSubscriptionStatus.idle && (!connectSocketSubscriptions || socketSubscriptionLandscapeChanged(diagramGroupsSubscriptionStatus) || socketSubscriptionVersionChanged(diagramGroupsSubscriptionStatus))) {
      this.context.commit('diagram/diagramGroupsUnsubscribe', undefined, { root: true })
      this.context.commit('diagram/setDiagramGroups', [], { root: true })
    }
    if (!domainsSubscriptionStatus.idle && socketSubscriptionVersionChanged(domainsSubscriptionStatus)) {
      this.context.commit('version/setTravelling', true, { root: true })
      this.context.commit('domain/setDomainsCache', rootState.domain.domainsCurrent, { root: true })
    }
    if (!domainsSubscriptionStatus.idle && (!connectSocketSubscriptions || socketSubscriptionLandscapeChanged(domainsSubscriptionStatus) || socketSubscriptionVersionChanged(domainsSubscriptionStatus))) {
      this.context.commit('domain/domainsUnsubscribe', undefined, { root: true })
      this.context.commit('domain/setDomain', [], { root: true })
    }
    if (!editorSubscriptionStatus.idle && prevLandscapeId && (!connectSocketSubscriptions || socketSubscriptionLandscapeChanged(editorSubscriptionStatus))) {
      this.socket?.emit('editor-location-update', { landscapeId: prevLandscapeId, location: {} })
      this.socket?.emit('editor-typing-update', { landscapeId: prevLandscapeId, typing: {} })
    }
    if (!editorSubscriptionStatus.idle && (!connectSocketSubscriptions || socketSubscriptionLandscapeChanged(editorSubscriptionStatus))) {
      this.context.commit('editor/editorUnsubscribe', undefined, { root: true })
      this.context.commit('editor/setLocations', [], { root: true })
      this.context.commit('editor/setTypings', [], { root: true })
    }
    if (!flowsSubscriptionStatus.idle && socketSubscriptionVersionChanged(flowsSubscriptionStatus)) {
      this.context.commit('version/setTravelling', true, { root: true })
      this.context.commit('flow/setFlowsCache', rootState.flow.flowsCurrent, { root: true })
    }
    if (!flowsSubscriptionStatus.idle && (!connectSocketSubscriptions || socketSubscriptionLandscapeChanged(flowsSubscriptionStatus) || socketSubscriptionVersionChanged(flowsSubscriptionStatus))) {
      this.context.commit('flow/flowsUnsubscribe', undefined, { root: true })
      this.context.commit('flow/setFlows', [], { root: true })
    }
    if (!objectsSubscriptionStatus.idle && socketSubscriptionVersionChanged(objectsSubscriptionStatus)) {
      this.context.commit('version/setTravelling', true, { root: true })
      this.context.commit('model/setObjectsCache', rootState.model.objectsCurrent, { root: true })
    }
    if (!objectsSubscriptionStatus.idle && (!connectSocketSubscriptions || socketSubscriptionLandscapeChanged(objectsSubscriptionStatus) || socketSubscriptionVersionChanged(objectsSubscriptionStatus))) {
      this.context.commit('model/objectsUnsubscribe', undefined, { root: true })
      this.context.commit('model/setObjects', [], { root: true })
    }
    if (!connectionsSubscriptionStatus.idle && socketSubscriptionVersionChanged(connectionsSubscriptionStatus)) {
      this.context.commit('version/setTravelling', true, { root: true })
      this.context.commit('model/setConnectionsCache', rootState.model.connectionsCurrent, { root: true })
    }
    if (!connectionsSubscriptionStatus.idle && (!connectSocketSubscriptions || socketSubscriptionLandscapeChanged(connectionsSubscriptionStatus) || socketSubscriptionVersionChanged(connectionsSubscriptionStatus))) {
      this.context.commit('model/connectionsUnsubscribe', undefined, { root: true })
      this.context.commit('model/setConnections', [], { root: true })
    }
    if (!tagsSubscriptionStatus.idle && socketSubscriptionVersionChanged(tagsSubscriptionStatus)) {
      this.context.commit('version/setTravelling', true, { root: true })
      this.context.commit('tag/setTagsCache', rootState.tag.tagsCurrent, { root: true })
    }
    if (!tagsSubscriptionStatus.idle && (!connectSocketSubscriptions || socketSubscriptionLandscapeChanged(tagsSubscriptionStatus) || socketSubscriptionVersionChanged(tagsSubscriptionStatus))) {
      this.context.commit('tag/tagsUnsubscribe', undefined, { root: true })
      this.context.commit('tag/setTags', [], { root: true })
    }
    if (!tagGroupsSubscriptionStatus.idle && socketSubscriptionVersionChanged(tagGroupsSubscriptionStatus)) {
      this.context.commit('version/setTravelling', true, { root: true })
      this.context.commit('tag/setTagGroupsCache', rootState.tag.tagGroupsCurrent, { root: true })
    }
    if (!tagGroupsSubscriptionStatus.idle && (!connectSocketSubscriptions || socketSubscriptionLandscapeChanged(tagGroupsSubscriptionStatus) || socketSubscriptionVersionChanged(tagGroupsSubscriptionStatus))) {
      this.context.commit('tag/tagGroupsUnsubscribe', undefined, { root: true })
      this.context.commit('tag/setTagGroups', [], { root: true })
    }

    if (
      rootState.version.travelling &&
      commentsSubscriptionStatus.successInfo.landscapeId === landscapeId && commentsSubscriptionStatus.successInfo.versionId === versionId &&
      connectionsSubscriptionStatus.successInfo.landscapeId === landscapeId && connectionsSubscriptionStatus.successInfo.versionId === versionId &&
      diagramsSubscriptionStatus.successInfo.landscapeId === landscapeId && diagramsSubscriptionStatus.successInfo.versionId === versionId &&
      diagramContentsSubscriptionStatus.successInfo.landscapeId === landscapeId && diagramContentsSubscriptionStatus.successInfo.versionId === versionId &&
      diagramGroupsSubscriptionStatus.successInfo.landscapeId === landscapeId && diagramGroupsSubscriptionStatus.successInfo.versionId === versionId &&
      domainsSubscriptionStatus.successInfo.landscapeId === landscapeId && domainsSubscriptionStatus.successInfo.versionId === versionId &&
      flowsSubscriptionStatus.successInfo.landscapeId === landscapeId && flowsSubscriptionStatus.successInfo.versionId === versionId &&
      objectsSubscriptionStatus.successInfo.landscapeId === landscapeId && objectsSubscriptionStatus.successInfo.versionId === versionId &&
      tagGroupsSubscriptionStatus.successInfo.landscapeId === landscapeId && tagGroupsSubscriptionStatus.successInfo.versionId === versionId &&
      tagsSubscriptionStatus.successInfo.landscapeId === landscapeId && tagsSubscriptionStatus.successInfo.versionId === versionId
    ) {
      this.context.commit('version/setTravelling', false, { root: true })
      this.context.commit('diagram/setDiagramsCache', null, { root: true })
      this.context.commit('diagram/setDiagramContentsCache', null, { root: true })
      this.context.commit('diagram/setDiagramGroupsCache', null, { root: true })
      this.context.commit('domain/setDomainsCache', null, { root: true })
      this.context.commit('flow/setFlowsCache', null, { root: true })
      this.context.commit('model/setObjectsCache', null, { root: true })
      this.context.commit('model/setConnectionsCache', null, { root: true })
      this.context.commit('tag/setTagsCache', null, { root: true })
      this.context.commit('tag/setTagGroupsCache', null, { root: true })
    }

    if (!connectSocketSubscriptions) {
      this.context.commit('setSocketLastDisconnected', 0)
    }

    if (!connectSocket && this.socket) {
      this.context.commit('setSocket', null)
    }

    if (connectSocket && !this.socket) {
      await this.context.dispatch('connectSocket')
    }

    const promises: Promise<void>[] = []

    if ((landscapeSubscriptionStatus.idle || opts.reconnect) && connectSocketSubscriptions && landscapeId) {
      promises.push(
        this.context.dispatch('landscape/landscapeSubscribe', {
          landscapeId,
          reconnect: opts.reconnect
        }, { root: true })
      )
    }
    if ((versionsSubscriptionStatus.idle || opts.reconnect) && connectSocketSubscriptions && landscapeId) {
      promises.push(
        this.context.dispatch('version/versionsSubscribe', {
          landscapeId,
          reconnect: opts.reconnect
        }, { root: true })
      )
    }

    if ((commentsSubscriptionStatus.idle || opts.reconnect) && connectSocketSubscriptions && landscapeId && versionId) {
      promises.push(
        this.context.dispatch('comment/commentsSubscribe', {
          landscapeId,
          reconnect: opts.reconnect,
          versionId
        }, { root: true })
      )
    }
    if ((diagramsSubscriptionStatus.idle || opts.reconnect) && connectSocketSubscriptions && landscapeId && versionId) {
      promises.push(
        this.context.dispatch('diagram/diagramsSubscribe', {
          landscapeId,
          reconnect: opts.reconnect,
          versionId
        }, { root: true })
      )
    }
    if ((diagramContentsSubscriptionStatus.idle || opts.reconnect) && connectSocketSubscriptions && landscapeId && versionId) {
      promises.push(
        this.context.dispatch('diagram/diagramContentsSubscribe', {
          landscapeId,
          reconnect: opts.reconnect,
          versionId
        }, { root: true })
      )
    }
    if ((diagramGroupsSubscriptionStatus.idle || opts.reconnect) && connectSocketSubscriptions && landscapeId && versionId) {
      promises.push(
        this.context.dispatch('diagram/diagramGroupsSubscribe', {
          landscapeId,
          reconnect: opts.reconnect,
          versionId
        }, { root: true })
      )
    }
    if ((domainsSubscriptionStatus.idle || opts.reconnect) && connectSocketSubscriptions && landscapeId && versionId) {
      promises.push(
        this.context.dispatch('domain/domainsSubscribe', {
          landscapeId,
          reconnect: opts.reconnect,
          versionId
        }, { root: true })
      )
    }
    if ((editorSubscriptionStatus.idle || opts.reconnect) && connectSocketSubscriptions && landscapeId) {
      promises.push((async () => {
        await this.context.dispatch('editor/editorSubscribe', {
          landscapeId,
          reconnect: opts.reconnect
        }, { root: true })
        this.socket?.emit('editor-location-update', { landscapeId, location: { versionId } })
      })())
    }
    if ((flowsSubscriptionStatus.idle || opts.reconnect) && connectSocketSubscriptions && landscapeId && versionId) {
      promises.push(
        this.context.dispatch('flow/flowsSubscribe', {
          landscapeId,
          reconnect: opts.reconnect,
          versionId
        }, { root: true })
      )
    }
    if ((objectsSubscriptionStatus.idle || opts.reconnect) && connectSocketSubscriptions && landscapeId && versionId) {
      promises.push(
        this.context.dispatch('model/objectsSubscribe', {
          landscapeId,
          reconnect: opts.reconnect,
          versionId
        }, { root: true })
      )
    }
    if ((connectionsSubscriptionStatus.idle || opts.reconnect) && connectSocketSubscriptions && landscapeId && versionId) {
      promises.push(
        this.context.dispatch('model/connectionsSubscribe', {
          landscapeId,
          reconnect: opts.reconnect,
          versionId
        }, { root: true })
      )
    }
    if ((tagsSubscriptionStatus.idle || opts.reconnect) && connectSocketSubscriptions && landscapeId && versionId) {
      promises.push(
        this.context.dispatch('tag/tagsSubscribe', {
          landscapeId,
          reconnect: opts.reconnect,
          versionId
        }, { root: true })
      )
    }
    if ((tagGroupsSubscriptionStatus.idle || opts.reconnect) && connectSocketSubscriptions && landscapeId && versionId) {
      promises.push(
        this.context.dispatch('tag/tagGroupsSubscribe', {
          landscapeId,
          reconnect: opts.reconnect,
          versionId
        }, { root: true })
      )
    }

    await Promise.all(promises)
  }
}
