import { ShareLinkOptions } from '@icepanel/platform-api-client'
import Vue from 'vue'
import { NavigationGuardNext, Route, RouteConfig } from 'vue-router'
import { getModule } from 'vuex-module-decorators'

import Status from '@/helpers/status'
import EditorAppbarHelp from '@/modules/editor/components/appbar/help.vue'
import EditorAppbarInfo from '@/modules/editor/components/appbar/info.vue'
import { EditorModule } from '@/modules/editor/store'
import store from '@/plugins/store'

import { UserModule } from '../user/store'
import AppbarIcePanelLink from './components/appbar/icepanel-link.vue'
import AppbarLogo from './components/appbar/logo.vue'
import AppbarLogoDense from './components/appbar/logo-dense.vue'
import AppbarTitle from './components/appbar/title.vue'
import { ShareModule } from './store'

const meta = {
  background: '#323435',
  disableScroll: true,
  hasAppbar: true,
  requiresAuth: false
}

const metaLogin = {
  background: 'lights',
  title: 'Share link login'
}

const defaultShareLinkOptions: ShareLinkOptions = {
  connectionIds: null,
  createdAt: '',
  createdBy: 'user',
  createdById: '',
  diagramId: null,
  domainId: null,
  drawer: null,
  expandedIds: null,
  filterIds: null,
  filterIdsExcluded: null,
  filterIdsIncluded: null,
  flowId: null,
  flowParentIds: null,
  flowPathIds: null,
  flowStepId: null,
  hash: '',
  id: '',
  mode: 'diagrams',
  modelId: null,
  objectIds: null,
  objectIdsFocused: null,
  objectTab: null,
  overlayGroupId: null,
  overlayIdsFocused: null,
  overlayIdsHidden: null,
  overlayIdsPinned: null,
  overlayTab: null,
  preventNavigation: false,
  search: null,
  shareLinkId: '',
  shortId: '-',
  x1: null,
  x2: null,
  y: null,
  y1: null,
  y2: null
}

const loadShareLink = async (to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
  const shareModule = getModule(ShareModule, store)
  const editorModule = getModule(EditorModule, store)
  const userModule = getModule(UserModule, store)

  const shortId = to.params.shortId
  const optionsShortId = to.params.optionsShortId

  if (shareModule.shareLinkPublicLandscapeFindStatus.successInfo.shortId === shortId && shareModule.shareLinkPublicOptionsFindStatus.successInfo.shortId === optionsShortId) {
    next()
  } else {
    await editorModule.loadResources()

    try {
      const { shareLink, shareLinkOrganizationAuth } = await shareModule.shareLinkPublicFind(shortId)

      if (optionsShortId === '-' && !shareModule.shareLinkOptions) {
        shareModule.setShareLinkOptions(defaultShareLinkOptions)
        shareModule.setShareLinkPublicOptionsFindStatus(Status.success({ shortId: '-' }))
      }

      if (shareLinkOrganizationAuth && !userModule.firebaseUser) {
        next({
          name: 'share-link-login-saml',
          params: to.params,
          query: {
            ...to.query,
            route: to.name !== 'share-diagrams' ? to.name : undefined
          }
        })
      } else if (!shareLinkOrganizationAuth && shareLink.protected) {
        next({
          name: 'share-link-login',
          params: to.params,
          query: {
            ...to.query,
            route: to.name !== 'share-diagrams' ? to.name : undefined
          }
        })
      } else {
        await shareModule.shareLinkPublicLandscapeFind(shortId)

        if (!shareModule.shareLinkOptions && optionsShortId) {
          await shareModule.shareLinkPublicOptionsFind({
            optionsShortId,
            shortId
          })
        }

        if (!shareModule.shareLinkOptions) {
          next({
            name: 'share-link-not-found'
          })
          return
        }

        // do not redirect when an existing path is set, otherwise redirect to the share link specified path
        if (window.location.pathname.split('/').filter(o => o).length > 2) {
          next()
          return
        }

        if (shareModule.shareLinkOptions.mode === 'overview') {
          next({
            name: 'share-overview',
            params: {
              optionsShortId,
              shortId
            },
            query: {
              domain: shareModule.shareLinkOptions.domainId || undefined,
              ...to.query
            }
          })
        } else if (shareModule.shareLinkOptions.mode === 'diagrams') {
          next({
            name: 'share-diagrams',
            params: {
              optionsShortId,
              shortId
            },
            query: {
              domain: shareModule.shareLinkOptions.domainId || undefined,
              ...to.query
            }
          })
        } else if (shareModule.shareLinkOptions.mode === 'model-objects') {
          next({
            name: 'share-model-objects',
            params: {
              optionsShortId,
              shortId
            },
            query: {
              domain: shareModule.shareLinkOptions.domainId || undefined,
              expanded: shareModule.shareLinkOptions.expandedIds?.length ? shareModule.shareLinkOptions.expandedIds : undefined,
              filter: shareModule.shareLinkOptions.filterIds?.length ? shareModule.shareLinkOptions.filterIds : undefined,
              filter_exclude: shareModule.shareLinkOptions.filterIdsExcluded?.length ? shareModule.shareLinkOptions.filterIdsExcluded : undefined,
              filter_include: shareModule.shareLinkOptions.filterIdsIncluded?.length ? shareModule.shareLinkOptions.filterIdsIncluded : undefined,
              object: shareModule.shareLinkOptions.objectIds?.length ? shareModule.shareLinkOptions.objectIds : undefined,
              object_tab: shareModule.shareLinkOptions.objectTab || undefined,
              search: shareModule.shareLinkOptions.search || undefined,
              y: shareModule.shareLinkOptions.y ? `${shareModule.shareLinkOptions.y}` : undefined,
              ...to.query
            }
          })
        } else if (shareModule.shareLinkOptions.mode === 'flows') {
          next({
            name: 'share-flows',
            params: {
              optionsShortId,
              shortId
            },
            query: {
              domain: shareModule.shareLinkOptions.domainId || undefined,
              ...to.query
            }
          })
        } else if (shareModule.shareLinkOptions.mode === 'dependencies') {
          next({
            name: 'share-model-dependencies',
            params: {
              optionsShortId,
              shortId
            },
            query: {
              connection: shareModule.shareLinkOptions.connectionIds?.length ? shareModule.shareLinkOptions.connectionIds : undefined,
              domain: shareModule.shareLinkOptions.domainId || undefined,
              object: shareModule.shareLinkOptions.objectIds?.length ? shareModule.shareLinkOptions.objectIds : undefined,
              object_focus: shareModule.shareLinkOptions.objectIdsFocused?.length ? shareModule.shareLinkOptions.objectIdsFocused : undefined,
              object_tab: shareModule.shareLinkOptions.objectTab || undefined,
              ...to.query
            }
          })
        } else if (shareModule.shareLinkOptions.mode === 'diagram' || shareModule.shareLinkOptions.mode === 'flow') {
          next({
            name: 'share-diagram',
            params: {
              optionsShortId,
              shortId
            },
            query: {
              connection: shareModule.shareLinkOptions.connectionIds?.length ? shareModule.shareLinkOptions.connectionIds : undefined,
              diagram: shareModule.shareLinkOptions.diagramId || undefined,
              drawer: shareModule.shareLinkOptions.drawer || undefined,
              flow: shareModule.shareLinkOptions.flowId || undefined,
              flow_parent: shareModule.shareLinkOptions.flowParentIds || undefined,
              flow_path: shareModule.shareLinkOptions.flowPathIds || undefined,
              flow_step: shareModule.shareLinkOptions.flowStepId || undefined,
              model: shareModule.shareLinkOptions.modelId || undefined,
              object: shareModule.shareLinkOptions.objectIds?.length ? shareModule.shareLinkOptions.objectIds : undefined,
              object_tab: shareModule.shareLinkOptions.objectTab || undefined,
              overlay_focus: shareModule.shareLinkOptions.overlayIdsFocused?.length ? shareModule.shareLinkOptions.overlayIdsFocused : undefined,
              overlay_group: shareModule.shareLinkOptions.overlayGroupId || undefined,
              overlay_hide: shareModule.shareLinkOptions.overlayIdsHidden?.length ? shareModule.shareLinkOptions.overlayIdsHidden : undefined,
              overlay_pin: shareModule.shareLinkOptions.overlayIdsPinned?.length ? shareModule.shareLinkOptions.overlayIdsPinned : undefined,
              overlay_tab: shareModule.shareLinkOptions.overlayTab || undefined,
              x1: shareModule.shareLinkOptions.x1 ? `${shareModule.shareLinkOptions.x1}` : undefined,
              x2: shareModule.shareLinkOptions.x2 ? `${shareModule.shareLinkOptions.x2}` : undefined,
              y1: shareModule.shareLinkOptions.y1 ? `${shareModule.shareLinkOptions.y1}` : undefined,
              y2: shareModule.shareLinkOptions.y2 ? `${shareModule.shareLinkOptions.y2}` : undefined,
              ...to.query
            }
          })
        } else {
          next({
            name: 'share-overview',
            params: {
              optionsShortId,
              shortId
            },
            query: to.query
          })
        }
      }
    } catch (err: any) {
      if (err.status === 404) {
        next({
          name: 'share-link-not-found'
        })
      } else if (err.status === 401) {
        next({
          name: 'share-link-login-saml',
          params: to.params,
          query: {
            ...to.query,
            route: to.name !== 'share-overview' ? to.name : undefined
          }
        })
      } else {
        throw err
      }
    }
  }
}

export default [
  {
    path: '/:shortId/:optionsShortId',
    redirect: to => ({
      name: 'share-landscape',
      params: to.params
    })
  },
  {
    component: () => import(/* webpackChunkName: "share-login" */ './views/login.vue'),
    meta: metaLogin,
    name: 'share-link-login',
    path: '/:shortId/:optionsShortId/login'
  },
  {
    component: () => import(/* webpackChunkName: "share-login-saml" */ './views/login-saml.vue'),
    meta: metaLogin,
    name: 'share-link-login-saml',
    path: '/:shortId/:optionsShortId/login/saml'
  },
  {
    component: () => import(/* webpackChunkName: "share-not-found" */ './views/not-found.vue'),
    meta: {
      background: 'lights',
      title: 'Share link not found'
    },
    name: 'share-link-not-found',
    path: '/share-link-not-found'
  },
  {
    beforeEnter: loadShareLink,
    children: [
      {
        component: () => import(/* webpackChunkName: "overview" */ '@/modules/overview/views/overview.vue'),
        name: 'share-overview',
        path: 'overview'
      },
      {
        component: () => import(/* webpackChunkName: "diagrams" */ '@/modules/diagram/views/diagrams.vue'),
        name: 'share-diagrams',
        path: 'diagrams'
      },
      {
        component: () => import(/* webpackChunkName: "flows" */ '@/modules/flow/views/flows.vue'),
        name: 'share-flows',
        path: 'flows'
      },
      {
        component: () => import(/* webpackChunkName: "model-objects" */ '@/modules/model/views/objects.vue'),
        name: 'share-model-objects',
        path: 'model/objects'
      },
      {
        component: () => import(/* webpackChunkName: "dependencies" */ '@/modules/model/views/dependencies.vue'),
        name: 'share-model-dependencies',
        path: 'model/dependencies'
      }
    ],
    components: {
      'appbar-left-1': AppbarLogo,
      'appbar-right-1': AppbarIcePanelLink,
      'appbar-right-2': EditorAppbarHelp,
      'appbar-title': AppbarTitle,
      default: () => import(/* webpackChunkName: "landscape" */ './views/landscape.vue')
    },
    meta,
    name: 'share-landscape',
    path: '/:shortId/:optionsShortId/landscape',
    redirect: to => ({
      name: 'share-overview',
      params: {
        optionsShortId: to.params.optionsShortId || '-',
        shortId: to.params.shortId
      }
    })
  },
  {
    beforeEnter: loadShareLink,
    components: {
      'appbar-left-1': AppbarLogoDense,
      'appbar-left-2': EditorAppbarInfo,
      'appbar-right-1': AppbarIcePanelLink,
      'appbar-right-2': EditorAppbarHelp,
      'appbar-title': AppbarTitle,
      default: () => import(/* webpackChunkName: "share-viewer" */ './views/viewer.vue')
    },
    meta,
    name: 'share-diagram',
    path: '/:shortId/:optionsShortId/landscape/diagrams/viewer'
  },

  // Deprecated
  {
    path: '/:shortId',
    redirect: to => {
      if (to.params.shortId.length === 10) {
        const shareModule = getModule(ShareModule, store)
        shareModule.setShareLinkOptions(defaultShareLinkOptions)
        shareModule.setShareLinkPublicOptionsFindStatus(Status.success({
          shortId: defaultShareLinkOptions.shortId
        }))
        return {
          name: 'share-landscape',
          params: {
            optionsShortId: '-',
            shortId: to.params.shortId
          }
        }
      } else {
        return {
          name: 'share-link-not-found'
        }
      }
    }
  },
  {
    path: '/:shortId/l',
    redirect: to => {
      if (to.params.shortId.length === 10) {
        const shareModule = getModule(ShareModule, store)
        shareModule.setShareLinkOptions({
          ...defaultShareLinkOptions,
          preventNavigation: true
        })
        shareModule.setShareLinkPublicOptionsFindStatus(Status.success({
          shortId: defaultShareLinkOptions.shortId
        }))
        return {
          name: 'share-diagram',
          params: {
            optionsShortId: '-',
            shortId: to.params.shortId
          }
        }
      } else {
        return {
          name: 'share-link-not-found'
        }
      }
    }
  },
  {
    path: '/:shortId/:mode/:perspectiveId',
    redirect: to => {
      if (to.params.shortId.length === 10) {
        const shareModule = getModule(ShareModule, store)
        const shareLinkOptions: ShareLinkOptions = {
          ...defaultShareLinkOptions,
          mode: 'diagram',
          overlayGroupId: to.params.perspectiveId && to.params.perspectiveId !== '-' ? to.params.perspectiveId : null,
          overlayTab: to.params.perspectiveId && to.params.perspectiveId !== '-' ? 'tags' : null,
          preventNavigation: to.params.mode === 'l'
        }
        shareModule.setShareLinkOptions(shareLinkOptions)
        shareModule.setShareLinkPublicOptionsFindStatus(Status.success({
          shortId: shareLinkOptions.shortId
        }))
        return {
          name: 'share-diagram',
          params: {
            optionsShortId: '-',
            shortId: to.params.shortId
          }
        }
      } else {
        return {
          name: 'share-link-not-found'
        }
      }
    }
  },
  {
    path: '/:shortId/:mode/:perspectiveId/:modelId/:diagramId/:x/:y/:scale',
    redirect: to => {
      if (to.params.shortId.length === 10) {
        const shareModule = getModule(ShareModule, store)
        const shareLinkOptions: ShareLinkOptions = {
          ...defaultShareLinkOptions,
          diagramId: to.params.diagramId && to.params.diagramId !== '-' ? to.params.diagramId : null,
          mode: 'diagram',
          modelId: to.params.modelId && to.params.modelId !== '-' ? to.params.modelId : null,
          overlayGroupId: to.params.perspectiveId && to.params.perspectiveId !== '-' ? to.params.perspectiveId : null,
          overlayTab: to.params.perspectiveId && to.params.perspectiveId !== '-' ? 'tags' : null,
          preventNavigation: to.params.mode === 'l'
        }
        shareModule.setShareLinkOptions(shareLinkOptions)
        shareModule.setShareLinkPublicOptionsFindStatus(Status.success({
          shortId: shareLinkOptions.shortId
        }))
        return {
          name: 'share-landscape',
          params: {
            optionsShortId: '-',
            shortId: to.params.shortId
          }
        }
      } else {
        return {
          name: 'share-link-not-found'
        }
      }
    }
  },

  {
    path: '/:shortId/model',
    redirect: to => ({
      name: 'share-model-objects',
      params: {
        optionsShortId: '-',
        shortId: to.params
      }
    })
  },
  {
    path: '/:shortId/overview',
    redirect: to => ({
      name: 'share-overview',
      params: {
        optionsShortId: '-',
        shortId: to.params
      }
    })
  },
  {
    path: '/:shortId/diagrams',
    redirect: to => ({
      name: 'share-diagram',
      params: {
        optionsShortId: '-',
        shortId: to.params
      }
    })
  },
  {
    path: '/:shortId/views',
    redirect: to => ({
      name: 'share-diagram',
      params: {
        optionsShortId: '-',
        shortId: to.params
      }
    })
  }
] as RouteConfig[]
