import { ShareLink, ShareLinkFrameLocation, ShareLinkOptions, ShareLinkOptionsRequired, ShareLinkOptionsService, ShareLinkPartial, ShareLinkPublicService, ShareLinkRequired, ShareLinkService, Version } from '@icepanel/platform-api-client'
import Vue from 'vue'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import Status from '@/helpers/status'

export interface IShareModule {
  shareLinks: ShareLink[]
  shareLinkUrls: Record<string, string>
  shareLinkOptions: ShareLinkOptions | null

  shareLinkPassword: string | null

  shareLinkInFrame: boolean
  shareLinkFrameLocation: ShareLinkFrameLocation | null

  shareLinkPublicOptionsFindStatus: Status<void, { shortId: string, optionsShortId: string }>
  shareLinkPublicLandscapeFindStatus: Status<void, { shortId: string }>
}

const name = 'share'

@Module({
  name,
  namespaced: true
})
export class ShareModule extends VuexModule implements IShareModule {
  static namespace = name

  shareLinks: ShareLink[] = []
  shareLinkUrls: Record<string, string> = {}
  shareLinkOptions: ShareLinkOptions | null = null

  shareLinkPassword: string | null = null

  shareLinkInFrame = false
  shareLinkFrameLocation: ShareLinkFrameLocation | null = null

  shareLinkPublicOptionsFindStatus = new Status<void, { shortId: string }>()
  shareLinkPublicLandscapeFindStatus = new Status<void, { shortId: string }>()

  @Mutation
  setShareLinks (shareLinks: ShareLink[]) {
    this.shareLinks = shareLinks
  }

  @Mutation
  setShareLink (shareLink: ShareLink) {
    this.shareLinks = [
      ...this.shareLinks?.filter(o => o.id !== shareLink.id) || [],
      shareLink
    ]
  }

  @Mutation
  setShareLinkOptions (shareLinkOptions: ShareLinkOptions | null) {
    this.shareLinkOptions = shareLinkOptions
  }

  @Mutation
  setShareLinkUrl ({ id, url }: { id: string, url: string }) {
    this.shareLinkUrls = {
      ...this.shareLinkUrls,
      [id]: url
    }
  }

  @Mutation
  setShareLinkPassword (shareLinkPassword: string | null) {
    this.shareLinkPassword = shareLinkPassword
  }

  @Mutation
  setShareLinkInFrame (shareLinkInFrame: boolean) {
    this.shareLinkInFrame = shareLinkInFrame
  }

  @Mutation
  setShareLinkFrameLocation (shareLinkFrameLocation: ShareLinkFrameLocation | null) {
    this.shareLinkFrameLocation = shareLinkFrameLocation
  }

  @Mutation
  pruneShareLinkUrls () {
    const shareLinkIds = this.shareLinks.map(o => o.id)
    Object.keys(this.shareLinkUrls).forEach(o => {
      if (!shareLinkIds.includes(o)) {
        Vue.delete(shareLinkIds, o)
      }
    })
  }

  @Mutation
  setShareLinkPublicOptionsFindStatus (...params: Parameters<typeof this.shareLinkPublicOptionsFindStatus.set>) {
    this.shareLinkPublicOptionsFindStatus.set(...params)
  }

  @Mutation
  setShareLinkPublicLandscapeFindStatus (...params: Parameters<typeof this.shareLinkPublicLandscapeFindStatus.set>) {
    this.shareLinkPublicLandscapeFindStatus.set(...params)
  }

  @Action({ rawError: true })
  async shareLinkFind ({ landscapeId, versionId }: { landscapeId: string, versionId: string }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    try {
      const { shareLink, url, stats } = await ShareLinkService.shareLinkFind(authorization, landscapeId, versionId)
      this.context.commit('setShareLink', shareLink)
      this.context.commit('setShareLinkUrl', { id: shareLink.id, url })
      return {
        shareLink,
        stats,
        url
      }
    } catch (err: any) {
      if (err.status === 404) {
        this.context.commit('setShareLinks', this.shareLinks.filter(o => o.versionId !== versionId))
        this.context.commit('pruneShareLinkUrls')
        return null
      } else {
        throw err
      }
    }
  }

  @Action({ rawError: true })
  async shareLinkCreate ({ landscapeId, versionId, props }: { landscapeId: string, versionId: string, props: ShareLinkRequired }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { shareLink, url } = await ShareLinkService.shareLinkCreate(authorization, landscapeId, versionId, props)
    this.context.commit('setShareLink', shareLink)
    this.context.commit('setShareLinkUrl', { id: shareLink.id, url })
    if (this.context.rootState.version.versions.some((o: Version) => o.id === versionId)) {
      await this.context.dispatch('version/versionFind', {
        landscapeId,
        versionId
      }, { root: true })
    }
    return shareLink
  }

  @Action({ rawError: true })
  async shareLinkUpdate ({ landscapeId, versionId, props, resetShortId }: { landscapeId: string, versionId: string, props: ShareLinkPartial, resetShortId?: boolean }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { shareLink } = await ShareLinkService.shareLinkUpdate(authorization, landscapeId, versionId, props, resetShortId)
    this.context.commit('setShareLink', shareLink)
    return shareLink
  }

  @Action({ rawError: true })
  async shareLinkDelete ({ landscapeId, versionId }: { landscapeId: string, versionId: string }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    await ShareLinkService.shareLinkDelete(authorization, landscapeId, versionId)
    this.context.commit('setShareLinks', this.shareLinks.filter(o => o.versionId !== versionId))
    this.context.commit('pruneShareLinkUrls')
    if (this.context.rootState.version.versions.some((o: Version) => o.id === versionId)) {
      await this.context.dispatch('version/versionFind', {
        landscapeId,
        versionId
      }, { root: true })
    }
  }

  @Action({ rawError: true })
  async shareLinkOptionsUpsert ({ landscapeId, versionId, props }: { landscapeId: string, versionId: string, props: ShareLinkOptionsRequired }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    try {
      const { shareLinkOptions } = await ShareLinkOptionsService.shareLinkOptionsUpsert(authorization, landscapeId, versionId, props)
      this.context.commit('setShareLinkOptions', shareLinkOptions)
      return shareLinkOptions
    } catch (err: any) {
      if (err.status === 404) {
        this.context.commit('setShareLinkOptions', null)
        return null
      } else {
        throw err
      }
    }
  }

  @Action({ rawError: true })
  async shareLinkPublicFind (shortId: string) {
    const {
      shareLink,
      shareLinkOrganizationAuth
    } = await ShareLinkPublicService.shareLinkPublicFind(shortId)
    this.context.commit('setShareLink', shareLink)
    return {
      shareLink,
      shareLinkOrganizationAuth
    }
  }

  @Action({ rawError: true })
  async shareLinkPublicOptionsFind ({ shortId, optionsShortId }: { shortId: string, optionsShortId: string }) {
    try {
      this.context.commit('setShareLinkPublicOptionsFindStatus', Status.loading())

      const authorization = this.shareLinkPassword ? `Basic ${this.shareLinkPassword}` : this.context.rootState.user.firebaseUser ? await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true }) : undefined
      const { shareLinkOptions } = await ShareLinkPublicService.shareLinkPublicOptionsFind(shortId, optionsShortId, authorization, this.shareLinkInFrame, this.shareLinkFrameLocation || undefined)
      this.context.commit('setShareLinkOptions', shareLinkOptions)

      this.context.commit('setShareLinkPublicOptionsFindStatus', Status.success({
        shortId: optionsShortId
      }))

      return shareLinkOptions
    } catch (err: any) {
      const message = err.body?.message || err.message
      this.context.commit('setShareLinkPublicOptionsFindStatus', Status.error(message))
      if (err.status !== 401) {
        this.context.commit('alert/pushAlert', { color: 'error', message }, { root: true })
      }
      throw err
    }
  }

  @Action({ rawError: true })
  async shareLinkPublicLandscapeFind (shortId: string) {
    try {
      this.context.commit('setShareLinkPublicLandscapeFindStatus', Status.loading())

      const authorization = this.shareLinkPassword ? `Basic ${this.shareLinkPassword}` : this.context.rootState.user.firebaseUser ? await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true }) : undefined
      const shareLink = await ShareLinkPublicService.shareLinkPublicLandscapeFind(shortId, authorization, this.shareLinkInFrame, this.shareLinkFrameLocation || undefined)

      this.context.commit('comment/setActiveComments', shareLink.comments, { root: true })
      this.context.commit('diagram/setDiagrams', shareLink.diagrams, { root: true })
      this.context.commit('diagram/setDiagramContents', shareLink.diagramContents, { root: true })
      this.context.commit('diagram/setDiagramThumbnails', shareLink.diagramThumbnails, { root: true })
      this.context.commit('diagram/setDiagramGroups', shareLink.diagramGroups, { root: true })
      this.context.commit('domain/setDomains', shareLink.domains, { root: true })
      this.context.commit('flow/setFlows', shareLink.flows, { root: true })
      this.context.commit('flow/setFlowThumbnails', shareLink.flowThumbnails, { root: true })
      this.context.commit('landscape/setLandscape', shareLink.landscape, { root: true })
      this.context.commit('model/setConnections', shareLink.modelConnections, { root: true })
      this.context.commit('model/setObjects', shareLink.modelObjects, { root: true })
      this.context.commit('tag/setTagGroups', shareLink.tagGroups, { root: true })
      this.context.commit('tag/setTags', shareLink.tags, { root: true })
      this.context.commit('version/setVersion', shareLink.version, { root: true })

      if (shareLink.landscape.organizationId && shareLink.organizationBrand) {
        this.context.commit('organization/setOrganizationBrand', {
          organizationBrand: shareLink.organizationBrand,
          organizationId: shareLink.landscape.organizationId
        }, { root: true })
      }

      this.context.commit('setShareLinkPublicLandscapeFindStatus', Status.success({
        shortId
      }))

      return shareLink
    } catch (err: any) {
      const message = err.body?.message || err.message
      this.context.commit('setShareLinkPublicLandscapeFindStatus', Status.error(message))
      if (err.status !== 401) {
        this.context.commit('alert/pushAlert', { color: 'error', message }, { root: true })
      }
      throw err
    }
  }

  @Action({ rawError: true })
  async shareLinkPublicDiagramView ({ shortId, diagramId }: { shortId: string, diagramId: string }) {
    const authorization = this.shareLinkPassword ? `Basic ${this.shareLinkPassword}` : this.context.rootState.user.firebaseUser ? await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true }) : undefined
    await ShareLinkPublicService.shareLinkPublicDiagramView(shortId, diagramId, authorization, this.shareLinkInFrame, this.shareLinkFrameLocation || undefined)
  }

  @Action({ rawError: true })
  async shareLinkPublicFlowView ({ shortId, flowId }: { shortId: string, flowId: string }) {
    const authorization = this.shareLinkPassword ? `Basic ${this.shareLinkPassword}` : this.context.rootState.user.firebaseUser ? await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true }) : undefined
    await ShareLinkPublicService.shareLinkPublicFlowView(shortId, flowId, authorization, this.shareLinkInFrame, this.shareLinkFrameLocation || undefined)
  }
}
